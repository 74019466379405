// IVA libraries
import '@iva/refresher';
import { handleSbrowserSid } from '@iva/sbrowser-sid';
import { startErrorHandling } from '@iva/error-reporter';
import { runLinkdecoration } from '@iva/linkdecoration';

// Modules
import { rcDebug as debug } from './modules/debug.js';
import { initConsentLogicInWindow } from './modules/consentManager';
import { retargetingHit } from './modules/retargetingHit';
import { conversionHit } from './modules/conversionHit';
import { handleCompatHit } from './modules/compatHit';

// Constants
import { DEBUGGER_STATES } from './modules/constants.js';

startErrorHandling({
  endpoint: 'sklik-ap-static',
  scriptName: 'rc.js',
});

debug({ message: 'rc.js started', state: DEBUGGER_STATES.START });

window.rc = window.rc || {};
window.rc.retargetingHit = retargetingHit;
window.rc.conversionHit = conversionHit;
delete window.rc.isStub;
initConsentLogicInWindow(debug);
debug({ message: 'Window object inited' });

if (!handleSbrowserSid()) {
  runLinkdecoration(null, 30000);
}

// backward compatible initial hits
handleCompatHit();

debug({ message: 'rc.js finished', state: DEBUGGER_STATES.DONE });
